@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

// Variables
$calendar-border: $border-color;
$cell-border: $border-color;
$current-time-color: $success;
$event-bg: $status-booked;
$event-border-radius: $border-radius;
$event-border: $event-bg;
$event-outline: $event-bg;
$event-padding: 3px 8px;
$event-zindex: 4;
$time-selection-bg-color: rgba($gray-900, 0.4);
$today-highlight-bg: $white;
$resource-width: 220px;

// Custom
$events-container-margin: 20px;

@import '~react-big-calendar/lib/sass/styles.scss';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

.rbc-time-header-content {
  .rbc-row.rbc-row-resource {
    border: 0;
  }
}

.rbc-time-content {
  border-width: 1px;
}

.rbc-timeslot-group {
  min-height: 150px;
}

.rbc-time-slot {
  .rbc-label {
    font-size: $font-size-sm;
  }
}

.rbc-day-slot {
  .rbc-events-container {
    margin: 0 $events-container-margin 0 1px;

    [dir='rtl'] & {
      margin: 0 1px 0 $events-container-margin;
    }
  }

  .rbc-event-content {
    [dir='rtl'] & {
      text-align: start;
    }
  }

  .rbc-event-label {
    font-size: 13px;
    padding: 0 10px 0 0;

    [dir='rtl'] & {
      padding: 0 0 0 10px;
    }
  }

  .rbc-time-slot {
    border-top-color: rgba($gray-300, 0.3);

    &:first-child {
      border-top: none;
    }
  }
}

.rbc-time-view-resources {
  .rbc-header,
  .rbc-day-bg {
    width: $resource-width;
  }

  .rbc-day-slot {
    min-width: $resource-width;
  }

  .rbc-time-gutter,
  .rbc-time-header-gutter {
    [dir='rtl'] & {
      left: unset;
    }
  }
}

.rbc-event-content {
  line-height: 1.1 !important;
  width: $resource-width;

  [dir='rtl'] & {
    text-align: start;
  }
}

.rbc-event,
.rbc-day-slot .rbc-event {
  $bg-angle: 45deg;
  $lighten: 9%;
  $darken: 2%;

  background: linear-gradient($bg-angle, $event-bg, lighten($event-bg, $lighten));
  border: none;
  color: $white;
  outline: none !important;

  &.rbc-selected {
    color: $white;
    background: linear-gradient($bg-angle, $event-bg, darken($event-bg, $darken));
  }

  &--conflicting {
    $bg-color: $status-conflicting;
    background: linear-gradient($bg-angle, $bg-color, lighten($bg-color, $lighten));

    &.rbc-selected {
      background: linear-gradient($bg-angle, $bg-color, darken($bg-color, $darken));
    }
  }

  &--noShow {
    $bg-color: $status-no-show;
    background: linear-gradient($bg-angle, $bg-color, lighten($bg-color, $lighten));

    &.rbc-selected {
      background: linear-gradient($bg-angle, $bg-color, darken($bg-color, $darken));
    }
  }

  &--waiting {
    $bg-color: $status-waiting;
    $color: $gray-800;
    color: $color;
    background: linear-gradient($bg-angle, $bg-color, lighten($bg-color, $lighten));
    font-weight: $font-weight-medium;

    &.rbc-selected {
      color: $color;
      background: linear-gradient($bg-angle, $bg-color, darken($bg-color, $darken));
    }
  }

  &--confirmed {
    $bg-color: $status-confirmed;
    background: linear-gradient($bg-angle, $bg-color, lighten($bg-color, $lighten));

    &.rbc-selected {
      background: linear-gradient($bg-angle, $bg-color, darken($bg-color, $darken));
    }
  }

  &--checkedIn {
    $bg-color: $status-checked-in;
    background: linear-gradient($bg-angle, $bg-color, lighten($bg-color, $lighten));

    &.rbc-selected {
      background: linear-gradient($bg-angle, $bg-color, darken($bg-color, $darken));
    }
  }
}

.rbc-slot-selection {
  background: linear-gradient(135deg, rgba($clinicy-color-1, 0.9) 10%, rgba($clinicy-color-2, 0.5));
  padding: 4px 8px;
  z-index: 9;

  [dir='rtl'] & {
    background: linear-gradient(-135deg, rgba($clinicy-color-1, 0.9) 10%, rgba($clinicy-color-2, 0.5));
  }
}

.rbc-background-event,
.rbc-day-slot .rbc-background-event {
  pointer-events: none;
  background: rgba($gray-600, 0.35);
  width: calc(100% - #{$events-container-margin}) !important;
}

.rbc-current-time-indicator {
  height: 2px;
}

// Custom
.rbc__disabledSlot {
  background-color: $gray-200;
  pointer-events: none;
}

.dayView {
  @include media-breakpoint-up(md) {
    .rbc-time-view-resources {
      $width: 340px;

      .rbc-header,
      .rbc-day-bg {
        width: $width;
      }

      .rbc-day-slot {
        min-width: $width;
      }
    }
  }
}
