@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import 'src/assets/styles/variables';

$expanded-menu-width: 300px;
$header-height: 110px;
$sygnet-width: 30px;
$logotype-width: 60px;
$logo-width: $sygnet-width + $logotype-width + 10px;
$ease: $easeClinicy;
$easeDuration: 0.5s;

.menu {
  background-color: $white;
  box-shadow: $box-shadow;
  height: 100%;
  overflow: hidden;
  position: fixed;
  transition: width $easeDuration $ease;
  user-select: none;
  width: $clinicy-menu-width;
  z-index: $zindex-menu;

  @include media-breakpoint-down(xl) {
    [dir='ltr'] & {
      left: -($clinicy-menu-width + 1);
    }

    [dir='rtl'] & {
      right: -($clinicy-menu-width + 1);
    }
  }
}

.menu--expanded {
  width: $expanded-menu-width;
  max-width: 100%;

  [dir='ltr'] & {
    left: 0;
  }

  [dir='rtl'] & {
    right: 0;
  }
}

.menu__cover {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-menu - 1;
}

.menu__close {
  position: absolute;
  top: 0;
  padding: $spacer;
  z-index: 1;

  [dir='ltr'] & {
    right: 0;
  }

  [dir='rtl'] & {
    left: 0;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.menu__header {
  position: relative;
  width: 100%;
  height: $header-height;
}

.menu__logo {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  position: absolute;
  transition: $easeDuration $ease;
  width: $logo-width;

  [dir='ltr'] & {
    margin-left: -45px;
  }

  [dir='rtl'] & {
    margin-right: 25px;
    flex-direction: row-reverse;
  }

  .menu--expanded & {
    $margin: calc((#{$expanded-menu-width} - #{$logo-width}) / 2);

    [dir='ltr'] & {
      margin-left: $margin;
    }

    [dir='rtl'] & {
      margin-right: $margin;
    }
  }
}

.menu__sygnet {
  width: $sygnet-width;
  height: auto;
}

.menu__logotype {
  width: $logotype-width;
  height: auto;
  filter: brightness(60%);
}

.menu__simpleBar {
  height: subtract(100vh, $header-height);
}

.menu__hiddenElement {
  opacity: 0;
  transition: opacity 0.15s;

  .menu--expanded & {
    opacity: 1;
    transition: opacity 0.3s;
  }
}
