@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.header {
  z-index: $zindex-header;

  &--sticky {
    position: sticky;
    top: 0;

    @include media-breakpoint-down(xl) {
      top: 0;
    }
  }

  // Language selector on mobile devices
  .languageSelector__dropdownToggle {
    .languageSelector__itemFlag {
      @include media-breakpoint-down(sm) {
        margin: 0 !important;
      }
    }

    .languageSelector__itemText {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.header__content {
  box-shadow: $box-shadow-lg;
  height: $clinicy-header-height;
  padding: 0 $clinicy-box-padding;
}

.header__menuTrigger {
  cursor: pointer;
  display: inline-block;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.header__item {
  [dir='ltr'] & {
    margin-right: $spacer-3;
  }

  [dir='rtl'] & {
    margin-left: $spacer-3;
  }

  @include media-breakpoint-down(sm) {
    [dir='ltr'] & {
      margin-right: $spacer-2;
    }

    [dir='rtl'] & {
      margin-left: $spacer-2;
    }
  }
}
