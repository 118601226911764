@import 'src/assets/styles/variables';

.infoBar {
  background-color: $warning;
  bottom: 0;
  color: $gray-800;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  left: 0;
  line-height: 1.2;
  padding: $spacer-1 90px $spacer-1 $spacer;
  position: fixed;
  width: 100%;
  z-index: $zindex-notification;

  &__content {
    align-items: center;
    display: flex;
  }
}
