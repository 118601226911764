@import 'src/assets/styles/variables';

$chip-margin: $spacer-2;
$chip-padding: ($spacer * 1.25);
$icon-size: 0.75rem;

.chips {
  flex-wrap: wrap;

  > * {
    margin-bottom: $chip-margin;

    [dir='ltr'] & {
      margin-right: $chip-margin;
    }

    [dir='rtl'] & {
      margin-left: $chip-margin;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.chip {
  align-items: center;
  border-radius: $border-radius-pill;
  border: 1px solid $gray-300;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.938rem;
  font-weight: $font-weight-medium;
  height: $input-height-lg;
  justify-content: center;
  padding: 0 $chip-padding;
  position: relative;
  transition: padding 0.2s, border-color 0.15s;
  user-select: none;
  white-space: nowrap;

  &:hover {
    border-color: $primary;
  }
}

.icon {
  color: $primary;
  position: absolute;
  opacity: 0;
  transform: translateY($icon-size) scale(0.5);
  transition: all 0.2s 0s;

  [dir='ltr'] & {
    right: $icon-size;
  }

  [dir='rtl'] & {
    left: $icon-size;
  }
}

.selectedChip {
  $padding: ($chip-padding + $icon-size + 0.125rem);

  border-color: $primary;

  [dir='ltr'] & {
    padding-right: $padding;
  }

  [dir='rtl'] & {
    padding-left: $padding;
  }

  .icon {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.invalidChip {
  border-color: $danger !important;
}
