@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

$fieldColor: $gray-600;
$fieldPlaceholderColor: $gray-600;
$fieldLineHeight: 1.375rem;

.selectedNote {
  animation: fadeIn 0.4s both;
  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-lg;
  left: 40px;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  user-select: none;
  width: 360px;
  z-index: -1;

  @include media-breakpoint-down(xl) {
    top: 40px;
    left: -166px; // 360/2 - 14px (point width / 2)
  }

  @include media-breakpoint-down(sm) {
    left: -136px; // 300/2 - 14px (point width / 2)
    width: 300px;
  }

  [dir='rtl'] & {
    left: auto;
    right: 20px; // 20px (rtl correction)

    @include media-breakpoint-down(xl) {
      left: auto;
      right: -186px; // 360/2 - 14px + 20px (rtl correction)
    }

    @include media-breakpoint-down(sm) {
      left: auto;
      right: -156px; // 300/2 - 14px + 20px (rtl correction)
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.title,
.description {
  color: $fieldColor;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  padding: 0;
  overflow-y: hidden;
  margin-bottom: -3px;
  line-height: $fieldLineHeight;

  &::placeholder {
    color: $fieldPlaceholderColor;
  }
}

.title {
  $padding: 70px;

  font-weight: $font-weight-bold;
  line-height: 22px;
  padding: 0 $padding 0 0;

  [dir='rtl'] & {
    padding: 0 0 0 $padding;
  }
}

.description {
  line-height: 22px;
  max-height: calc($fieldLineHeight * 8);
  overflow-y: auto;
}

.titleCompleted,
.descriptionCompleted {
  color: rgba($fieldColor, 0.6);
  text-decoration: line-through;

  &::placeholder {
    color: rgba($fieldColor, 0.6);
  }
}

.element {
  color: $secondary;
  display: block;
  font-size: $font-size-sm;
  margin-top: $spacer-1;
}

.actions {
  $actionsMargin: 10px;

  position: absolute;
  top: $actionsMargin;
  right: $actionsMargin;

  [dir='rtl'] & {
    right: auto;
    left: $actionsMargin;
  }
}

.action {
  cursor: pointer;
  color: rgba($fieldColor, 0.5);
  transition: color 0.15s;
  margin-right: 5px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 5px;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: $fieldColor;
  }
}
