@import 'src/assets/styles/variables';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(40px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

.fadeIn {
  animation: fadeIn 0.5s both;
}

.fadeOut {
  animation: fadeIn 0.5s reverse both;
}

.slideInUp {
  animation: fadeIn 0.4s both, slideInUp 0.8s $easeOutExpo both;
}

.slideInDown {
  animation: fadeIn 0.4s normal, slideInDown 0.8s $easeOutExpo normal;
}

.zoomInUp {
  animation: fadeIn 0.4s both, slideInUp 0.8s $easeOutExpo both, zoomIn 0.8s $easeOutExpo both;
}
