@import 'src/assets/styles/variables';

.buttons {
  background-color: $white;
  border-radius: $border-radius-pill;
  box-shadow: $clinicy-toolbar-box-shadow;
  display: inline-flex;
  padding: 4px;
}

.button {
  $margin: 4px;

  background: $white;
  border-radius: $border-radius-pill;
  cursor: pointer;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  padding: 7px 18px;
  transition: all 0.15s;
  user-select: none;
  margin: 0 0 0 $margin;

  [dir='rtl'] & {
    margin: 0 $margin 0 0;
  }

  &:first-child {
    margin: 0;
  }

  &:hover {
    background: $gray-100;
  }
}

.active {
  $background-color: darken($gray-200, 2%);
  background: $background-color;

  &:hover {
    background: $background-color;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
