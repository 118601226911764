@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.select-container {
  font-size: $input-font-size;

  .select__control {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    min-height: $input-height;
    transition: $input-transition;

    &:hover {
      border-color: $input-border-color;
    }

    &--is-focused {
      border-color: $input-focus-border-color;
      box-shadow: $input-btn-focus-box-shadow;

      &:hover {
        border-color: $input-focus-border-color;
      }
    }

    &--is-disabled {
      background-color: $input-disabled-bg;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__placeholder {
    color: $input-placeholder-color;
  }

  .select__value-container {
    padding: 0 $input-padding-x;
  }

  .select__value-container--is-multi {
    &.select__value-container--has-value {
      padding: $input-padding-x;
    }
  }

  .select__single-value {
    color: $input-color;
  }

  .select__multi-value {
    background-color: $light-primary;
    border-radius: $border-radius;
    transition: $input-transition;
  }

  .select__multi-value__remove {
    color: $input-color;
    cursor: pointer;
    transition: $input-transition;

    &:hover {
      background-color: transparent;
      color: $danger;
    }
  }

  .select__menu {
    border-radius: $dropdown-border-radius;
    box-shadow: 0 0 0 $dropdown-border-width $dropdown-border-color, $dropdown-box-shadow;
    z-index: $zindex-dropdown;
  }

  .select__menu-portal {
    z-index: $zindex-fixed;
  }

  .select__menu-list {
    padding: $dropdown-padding-y 0;
  }

  .select__menu-notice {
    color: $gray-500;
    font-size: $font-size-sm;
  }

  .select__option {
    color: $dropdown-color;
    font-size: $font-size-sm;
    transition: $input-transition;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:hover {
      background-color: $light-primary;
      color: $primary;
    }

    &--is-focused {
      background-color: $light-primary;
      color: $primary;
    }

    &--is-selected {
      background-color: $primary;
      color: $white;

      &:hover {
        background-color: lighten($primary, 4%);
        color: $white;
      }
    }

    &--is-disabled {
      background-color: $white;
      color: $gray-400;

      &:hover {
        background-color: $white;
        color: $gray-400;
      }
    }
  }
}

.select-container--invalid {
  .select__control {
    border: $input-border-width solid $danger;

    &:hover {
      border-color: $danger;
    }

    &--is-focused {
      $box-shadow-color: rgba($danger, $input-btn-focus-color-opacity);
      border-color: $danger;
      box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $box-shadow-color;

      &:hover {
        border-color: $danger;
      }
    }
  }
}

.select-container--sm {
  font-size: $input-font-size-sm;

  .select__control {
    border-radius: $input-border-radius-sm;
    min-height: $input-height-sm;
  }

  .select__option {
    padding: 0.375rem $dropdown-item-padding-x;
  }

  .select__value-container {
    padding: 0 $input-padding-x-sm;
  }

  .select__value-container--is-multi {
    &.select__value-container--has-value {
      padding: $input-padding-x-sm;
    }
  }
}

.select-container--lg {
  font-size: $input-font-size-lg;

  .select__control {
    border-radius: $input-border-radius-lg;
    min-height: $input-height-lg !important;
  }

  .select__value-container {
    padding: 0 $input-padding-x-lg;
  }

  .select__value-container--is-multi {
    &.select__value-container--has-value {
      padding: $input-padding-x-lg;
    }
  }
}
