@import 'src/assets/styles/variables';

.doctorSection {
  align-items: center;
  background: $gray-100;
  border-bottom: 1px solid $modal-header-border-color;
  margin: (-$modal-inner-padding) (-$modal-inner-padding) $spacer-4;
  padding: $spacer $modal-inner-padding;

  &__content {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }

  &__details {
    ul {
      color: $secondary;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &__avatar {
    align-items: center;
    background-color: white;
    border-radius: 3px;
    border: 2px solid white;
    margin-right: $spacer;

    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: $spacer;
    }
  }
}
