@import 'src/assets/styles/variables';

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  overflow: hidden;
}

.loader {
  $size: 150px;

  position: relative;
  height: $size;
  width: $size;
}

.loader #path {
  stroke-dasharray: 299, 301;
  stroke-dashoffset: 300;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fullScreenLoader {
  background-color: $white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  animation: fadeIn 0.5s both ease 0.5s;
}
