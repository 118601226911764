@import 'src/assets/styles/variables';

.phoneNumber__close {
  color: lighten($black, 80%);
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 9px;
  position: absolute;
  top: 0;
  right: 0;
  transition: color 0.2s;

  &:hover {
    color: lighten($black, 60%);
  }

  &--withIcon {
    right: 32px;
  }
}
