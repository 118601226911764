@import 'src/assets/styles/variables';

.dateRange {
  display: flex;
  align-items: center;
}

.dateText {
  white-space: nowrap;
}

.arrow {
  color: $secondary;
  margin: $spacer-2;

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
}
