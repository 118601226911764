@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.content {
  margin: calc(50vh - 150px) auto $spacer-4;
  max-width: 940px;
  padding: $spacer-4;
  text-align: center;
  user-select: none;

  @include media-breakpoint-down(xl) {
    margin: $spacer-5 auto $spacer-4;
  }
}

.title {
  color: $secondary;
  font-weight: $font-weight-light;
  margin-bottom: $spacer-6;

  @include media-breakpoint-down(md) {
    margin-bottom: $spacer-5;
  }
}

.template {
  align-items: center;
  background: $white;
  border-radius: $border-radius-lg;
  border: 2px solid $white;
  box-shadow: $box-shadow;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: $spacer-6 $spacer-4;
  text-align: center;
  transition: all 0.2s;

  @include media-breakpoint-down(md) {
    padding: $spacer-3;
  }
}

.selectedTemplate {
  border-color: $primary;
}
