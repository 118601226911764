@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.headerUserDropdown__dropdownToggle {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.headerUserDropdown__user {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.headerUserDropdown__userName {
  display: block;
}

.headerUserDropdown__userRole {
  display: block;
  font-size: $badge-font-size;
  margin-top: -0.125rem;
}
