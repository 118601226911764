@import 'src/assets/styles/variables';

.feedbackIcon {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: $white;

  // Success icon
  &-success {
    border-radius: 50%;
    border: 4px solid $success;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: $white;
    width: 80px;

    &:after,
    &:before {
      background: $white;
      content: '';
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }

    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }

    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }

    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgba($success, 0.25);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }

    &-fix {
      background-color: $white;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }

    &-tip,
    &-long {
      background-color: $success;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 2;
    }

    &-tip {
      left: 14px;
      top: 46px;
      transform: rotate(45deg);
      width: 25px;
      animation: animateSuccessTip 0.75s;
    }

    &-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px;
      animation: animateSuccessLong 0.75s;
    }
  }

  // Warning icon
  &-warning {
    border-radius: 50%;
    border: 4px solid $warning;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: $white;
    width: 80px;
    animation: scaleWarning 0.75s infinite alternate;

    &:after,
    &:before {
      background: $white;
      content: '';
      border-radius: 50%;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    &:before {
      display: inline-block;
      opacity: 0;
      animation: pulseWarning 2s linear infinite;
    }

    &:after {
      display: block;
      z-index: 1;
    }

    &-body {
      background-color: $warning;
      border-radius: 2px;
      height: 47px;
      left: 50%;
      margin-left: -2px;
      position: absolute;
      top: 10px;
      width: 5px;
      z-index: 2;
      animation: pulseWarningIns 0.75s infinite alternate;
    }

    &-dot {
      background-color: $warning;
      border-radius: 50%;
      bottom: 10px;
      height: 7px;
      left: 50%;
      margin-left: -3px;
      position: absolute;
      width: 7px;
      z-index: 2;
      animation: pulseWarningIns 0.75s infinite alternate;
    }
  }

  // Error icon
  &-error {
    border-radius: 50%;
    border: 4px solid $danger;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: $white;
    width: 80px;
    animation: animateErrorIcon 0.5s;

    &:after,
    &:before {
      background: $white;
      content: '';
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }

    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }

    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }

    &-x {
      display: block;
      position: relative;
      z-index: 2;
    }

    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgba(200, 0, 0, 0.2);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }

    &-fix {
      background-color: $white;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }

    &-left,
    &-right {
      border-radius: 2px;
      display: block;
      height: 5px;
      position: absolute;
      z-index: 2;
      background-color: $danger;
      top: 37px;
      width: 47px;
    }

    &-left {
      left: 17px;
      transform: rotate(45deg);
      animation: animateXLeft 0.75s;
    }

    &-right {
      right: 16px;
      transform: rotate(-45deg);
      animation: animateXRight 0.75s;
    }
  }
}

// Success animations
@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}

// Warning animations
@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseWarning {
  0% {
    background-color: $white;
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: $white;
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #f8bb86;
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}

// Error animations
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}

@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}
