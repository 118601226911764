@import 'src/assets/styles/variables';

.box {
  background: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: $grid-gutter-width;
  padding: $clinicy-box-padding;

  &--sm {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
  }

  &--md {
    margin: 0 auto;
    width: 100%;
    max-width: 580px;
  }

  &--lg {
    margin: 0 auto;
    width: 100%;
    max-width: 720px;
  }
}

.box__content {
  $margin: -$clinicy-box-padding;
  border-radius: $border-radius;

  &--full {
    margin: $margin;
  }

  &--fullWidth {
    margin-right: $margin;
    margin-left: $margin;
    margin-bottom: $margin;
  }
}

.box__header {
  display: flex;
  justify-content: space-between;
}
