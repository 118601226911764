@import 'src/assets/styles/variables';

$printColor: $gray-800;

.printComponent {
  padding: $spacer-6;
}

.logo {
  display: block;
  margin: $spacer-3 auto;
  width: 10rem;
}

.printHeading {
  color: $printColor;
  display: block;
  margin-bottom: $spacer-5;
  text-align: center;
}
