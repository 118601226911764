@import 'src/assets/styles/variables';

.phoneNumberRow {
  margin-bottom: $spacer-1;
  display: flex;
  flex-direction: row;
}

.phoneNumber {
  width: calc(100% - 70px);

  div {
    margin-bottom: 0 !important;

    label {
      display: none;
    }
  }
}

.actions {
  height: 34px;
  display: flex;
  align-items: center;
}
