@import 'src/assets/styles/variables';

.custom-breadcrumb {
  align-items: center;
  background-color: $gray-100;
  border-radius: $border-radius;
  border: 1px solid $gray-200;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 8px $spacer;

  &__item {
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;

    a {
      color: $body-color;
    }

    &-icon {
      color: $gray-500;
      font-size: 14px !important;
      margin: 0 4px;
      user-select: none;
    }
  }
}
