@import 'src/assets/styles/variables';

$day-button-width: 106px;
$day-button-margin: 4px;
$number-of-day-columns: 3;

.dateFormatter {
  width: ($day-button-width * $number-of-day-columns) + ($day-button-margin * ($number-of-day-columns * 2));
  margin: 0 auto;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 8px 0 16px;
    user-select: none;
    width: 100%;
  }

  &__day {
    margin: $day-button-margin !important;
    padding: 8px 0 !important;
    width: $day-button-width;
  }

  &__actionIcon {
    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
