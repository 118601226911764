@import 'src/assets/styles/variables';

$outlineSize: 2px;
$selectedColor: lighten($clinicy-color-2, 4%);
$selectedHoverSize: 4px;

.point {
  $size: 28px;

  align-items: center;
  background: rgba($gray-800, 0.45);
  border-radius: $border-radius-pill;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: $font-weight-medium;
  height: $size;
  justify-content: center;
  position: relative;
  user-select: none;
  width: $size;

  &::before {
    content: '';
    border-radius: $border-radius-pill;
    border: $outlineSize solid $white;
    top: -$outlineSize;
    right: -$outlineSize;
    bottom: -$outlineSize;
    left: -$outlineSize;
    position: absolute;
    transition: all 0.15s ease-out;
  }

  &:hover {
    &::before {
      border-width: $selectedHoverSize;
      top: -$selectedHoverSize;
      right: -$selectedHoverSize;
      bottom: -$selectedHoverSize;
      left: -$selectedHoverSize;
    }
  }

  [dir='rtl'] & {
    margin-right: -20px; // point correction for RTL
  }
}

.selectedPoint {
  animation: pulse 1.8s infinite;

  &::before {
    border-color: $selectedColor;
    border-width: $selectedHoverSize;
    top: -$selectedHoverSize;
    right: -$selectedHoverSize;
    bottom: -$selectedHoverSize;
    left: -$selectedHoverSize;
  }

  &:hover {
    outline-color: $selectedColor;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 $selectedHoverSize rgba($selectedColor, 1);
  }

  85% {
    box-shadow: 0 0 0 14px rgba($selectedColor, 0);
  }

  100% {
    box-shadow: 0 0 0 $selectedHoverSize rgba($selectedColor, 0);
  }
}
