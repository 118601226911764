@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.row {
  display: flex;

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.col {
  flex: 1;
}

.colFilters {
  flex: 0 0 322px;
  margin: 0 $spacer-4 0 0;

  [dir='rtl'] & {
    margin: 0 0 0 $spacer-4;
  }

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.colCalendar {
  overflow-x: hidden;
}

.calendarWrapper {
  height: 100vh;
}

.datePicker {
  border: none;
  box-shadow: $box-shadow;

  :global {
    .react-datepicker__header {
      background: $white;
      border-bottom: none;
      padding: 12px 4px 2px;
    }

    .react-datepicker__today-button {
      $color: darken($gray-200, 3%);
      background: $white;
      border-top-color: $color;

      &:hover {
        background: $color;
        border-top-color: $color;
      }
    }
  }
}

.calendarLoading {
  opacity: 0.6;
  pointer-events: none;
}

.box {
  min-height: 220px;
}

.shakingEvent {
  animation: shaking 7000ms ease-in-out infinite;
}

@keyframes shaking {
  $distance: 4px;

  0% {
    transform: translateY(0);
  }
  2% {
    transform: translateY($distance);
  }
  4% {
    transform: translateY(-$distance);
  }
  6% {
    transform: translateY($distance);
  }
  8% {
    transform: translateY(0);
  }
  10% {
    transform: translateY($distance);
  }
  12% {
    transform: translateY(-$distance);
  }
  14% {
    transform: translateY($distance);
  }
  16% {
    transform: translateY(0);
  }
  18% {
    transform: translateY($distance);
  }
  20% {
    transform: translateY(0);
  }
}
