@import 'src/assets/styles/variables';

$datepicker__background-color: $gray-100;
$datepicker__border-color: lighten($border-color, 4%);
$datepicker__highlighted-color: $success;
$datepicker__muted-color: $gray-400;
$datepicker__selected-color: lighten($primary, 4%);
$datepicker__text-color: $body-color;
$datepicker__header-color: $body-color;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius;
$datepicker__day-border-radius: $border-radius-pill;
$datepicker__day-margin: 0.125rem;
$datepicker__font-size: $font-size-sm;
$datepicker__font-family: inherit;
$datepicker__item-size: 2.4rem;
$datepicker__margin: 0.25rem;
$datepicker__navigation-button-size: 24px;
$datepicker__triangle-size: 5px;

$datepicker__time-container-size: 90px;
$datepicker__time-only-time-container-size: 110px;
