@import 'src/assets/styles/variables';

.accordionBody > div:first-child {
  padding: 0 !important;
}

.accordionHeader > button:first-child {
  padding: $spacer-3;
  font-weight: $font-weight-medium;

  &:not(:global(.collapsed)) {
    color: $body-color;
    background-color: $gray-200;
  }
}
