@import 'src/assets/styles/variables';

.collapseIcon {
  transition: transform 0.25s;

  &--active {
    transform-origin: center;

    [dir='ltr'] & {
      transform: rotate(-180deg);
    }

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
