@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.toolbar {
  position: absolute;
  bottom: $spacer-5;
  left: $spacer-5;
  display: inline-flex;
  padding: 15px 30px;
  background-color: rgba($gray-900, 0.45);
  border-radius: $border-radius-pill;
  z-index: 11;

  @include media-breakpoint-down(md) {
    padding: 11px 20px;
    bottom: $spacer-3;
    left: $spacer-3;
  }

  [dir='rtl'] & {
    left: auto;
    right: $spacer-5;

    @include media-breakpoint-down(md) {
      left: auto;
      right: $spacer-3;
    }
  }
}

.toolbarItem {
  $margin: 15px;

  cursor: pointer;
  align-items: center;
  color: $white;
  display: flex;
  justify-content: center;
  margin-right: $margin;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: $margin;
  }

  &:last-child {
    margin: 0;
  }

  &:before {
    border-radius: $border-radius-lg;
    content: '';
    height: 32px;
    position: absolute;
    width: 32px;
    z-index: 1;
    transition: all 0.15s ease-out;

    @include media-breakpoint-down(md) {
      height: 28px;
      width: 28px;
    }
  }

  &:hover {
    &:before {
      background-color: rgba($gray-900, 0.45);
      transform: scale(1.2);
    }
  }
}

.toolbarIcon {
  z-index: 2;
}
