@import 'src/assets/styles/variables';

.valueRangeWrapper {
  margin: 8px 0 $spacer;
  align-items: center;
  display: flex;
}

.valueRangeValue {
  flex: 0 40px;
  font-size: $font-size-sm;
  line-height: 1.1;
  text-align: center;
  user-select: none;
}

.valueRangeSlider {
  flex: 1;
  padding: 0 12px;
}
