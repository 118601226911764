@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.stickyPagination {
  align-items: center;
  background-color: $white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid $table-border-color;
  bottom: 0;
  box-shadow: 0 -1.2rem 1.2rem -0.5rem rgba($box-shadow-color, 0.05);
  display: flex;
  font-size: $font-size-sm;
  padding: 0.5rem $table-cell-padding-x;
  position: sticky;
  user-select: none;
}

.stickyPagination__pageSize {
  align-items: center;
  position: absolute;

  @include media-breakpoint-down(lg) {
    position: relative;
  }
}

.stickyPagination__pages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.stickyPagination__input {
  text-align: center;
  width: 3rem !important;
}

.stickyPagination__arrow {
  .icon {
    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
