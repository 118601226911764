@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  cursor: pointer;
  padding: $spacer-1;
  position: relative;
}

.toggleWithPill {
  [dir='ltr'] & {
    margin-right: $spacer-3;
  }

  [dir='rtl'] & {
    margin-left: $spacer-3;
  }
}

.badge {
  $minSize: 18px;

  align-items: center;
  animation: badgeAnimation 0.4s $easeOutBack both 0.2s;
  background-color: $danger;
  border-radius: $border-radius-pill;
  color: $white;
  display: flex;
  font-size: 11px;
  font-weight: $font-weight-medium;
  justify-content: center;
  min-height: $minSize;
  min-width: $minSize;
  padding: 1px 4px 0;
  position: absolute;
  top: 0;
  user-select: none;

  [dir='ltr'] & {
    right: -4px;
  }

  [dir='rtl'] & {
    left: -4px;
  }
}

@keyframes badgeAnimation {
  0% {
    opacity: 0;
    transform: translateY(-14px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu {
  width: 360px;
  max-width: 90vw;
  padding: 0 !important;
}

.header {
  align-items: center;
  border-bottom: 1px solid $dropdown-border-color;
  display: flex;
  justify-content: space-between;
  min-height: 63px;
  padding: $spacer-3;
}

.headerButton {
  padding: 3px 8px !important;

  @include media-breakpoint-down(sm) {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.simpleBar {
  max-height: 70vh;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  overflow: hidden;
}
