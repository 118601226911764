@import 'src/assets/styles/variables';

.languageSelector {
  display: flex;
  align-items: center;
}

.languageSelector__dropdownToggle {
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.languageSelector__item {
  display: flex;
  align-items: center;
}

.languageSelector__itemFlag {
  width: 20px;
  height: auto;
}
