@import 'src/assets/styles/variables';

.time-range {
  &__wrapper {
    margin: 8px 0 $spacer;
    align-items: center;
    display: flex;
  }

  &__time {
    flex: 0 40px;
    font-size: $font-size-sm;
    line-height: 1.1;
    text-align: center;
    user-select: none;
  }

  &__slider {
    flex: 1;
    padding: 0 12px;
  }
}
