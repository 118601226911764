@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.notification {
  display: flex;
  cursor: default;
  border-bottom: 1px solid $dropdown-border-color;
  padding: $spacer-3;
  position: relative;
  transition: background-color 0.15s, opacity 0.3s;

  [dir='ltr'] & {
    text-align: left;
  }

  [dir='rtl'] & {
    text-align: right;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $gray-100;

    :global {
      .closeAction {
        opacity: 1;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.status {
  $size: 7px;

  background-color: $gray-300;
  border-radius: 50%;
  height: $size;
  margin-top: 6px;
  width: $size;
}

.unread {
  background-color: $danger;
  box-shadow: 0 0 0 0 rgba($danger, 1);
  animation: unreadPulse 2.8s infinite 1s;
}

@keyframes unreadPulse {
  0% {
    box-shadow: 0 0 0 0 rgba($danger, 0.7);
  }

  40% {
    box-shadow: 0 0 0 6px rgba($danger, 0);
  }

  80% {
    box-shadow: 0 0 0 0 rgba($danger, 0);
  }
}

.title {
  color: $gray-600;
  margin-bottom: 0;

  [dir='ltr'] & {
    padding-right: $spacer;
  }

  [dir='rtl'] & {
    padding-left: $spacer;
  }
}

.date {
  color: $gray-400;
  font-size: 0.8rem;
  white-space: nowrap;
}

.description {
  color: $gray-400;
  line-height: 1.125rem;
  margin: 5px 0 -1px 0;
}

.close {
  cursor: pointer;
  position: absolute;
  top: $spacer-1;
  padding: $spacer-1;
  opacity: 0;
  transition: opacity 0.15s;

  [dir='ltr'] & {
    right: $spacer-1;
  }

  [dir='rtl'] & {
    left: $spacer-1;
  }

  @include media-breakpoint-down(xl) {
    opacity: 1;
  }
}
