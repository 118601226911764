@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.bookAppointmentTrigger {
  .bookAppointmentTrigger__buttonPlus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: $white;
    background-image: $clinicy-gradient-1;
    box-shadow: 0 0 0 $primary;
    cursor: pointer;
    animation: pulse 3s infinite;

    @include media-breakpoint-down(sm) {
      width: 26px;
      height: 26px;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(lighten($primary, 10%), 0.7);
  }
  40% {
    box-shadow: 0 0 0 12px rgba(lighten($primary, 10%), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(lighten($primary, 10%), 0);
  }
}
