@import 'src/assets/styles/variables';

.permissionSwitchWrapper {
  width: 100%;

  :global(.form-check) {
    margin-bottom: 0;
  }
}

.permissionSwitchLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0 !important;
  padding: 12px $spacer-4;
  cursor: pointer;
}

.permissionSwitchInput {
  cursor: pointer;
  margin-left: 0 !important;
}
