@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.wrapper {
  $margin: 350px;

  bottom: $spacer;
  display: flex;
  justify-content: center;
  margin: 0 0 0 $margin;
  pointer-events: none;
  position: sticky;
  z-index: $zindex-sticky + 1;

  [dir='rtl'] & {
    margin: 0 $margin 0 0;
  }

  @include media-breakpoint-down(xl) {
    margin: 0 !important;
  }
}

.toolbar {
  align-items: center;
  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $clinicy-toolbar-box-shadow;
  display: flex;
  padding: $spacer-2 $spacer-3;
  pointer-events: auto;
}

.toolbarEnter {
  opacity: 0;
  transform: translateY(30px);
}

.toolbarEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 250ms;
}

.toolbarExit {
  opacity: 1;
}

.toolbarExitActive {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 200ms, transform 250ms;
}
