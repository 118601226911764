@import 'src/assets/styles/variables';

$file-uploader-padding: $input-btn-padding-x;

.file-uploader {
  user-select: none;

  .file-uploader__dropzone.form-control {
    background-color: $light-primary;
    border-radius: $border-radius;
    border: 2px dashed $primary;
    font-size: $font-size-sm;
    padding: 36px $file-uploader-padding 22px;
    text-align: center;

    &:hover {
      border: 2px dashed $primary;
    }

    &.is-invalid {
      border: 2px dashed $danger;

      &:hover {
        border: 2px dashed $danger;
      }
    }
  }

  .file-uploader__information {
    color: $gray-700;
  }

  .file-uploader__preview {
    background: $light-primary no-repeat center center;
    background-size: cover;
    border-radius: $border-radius;
    border: 2px solid $white;
    height: 100px;
    margin: 0 auto $spacer;
    width: 100px;
  }

  .file-uploader__file {
    font-size: $font-size-sm;
    margin-bottom: $spacer;
  }

  .file-uploader__file-label {
    font-weight: $font-weight-medium;
  }

  .file-uploader__file-link {
    margin: 0 5px;
  }

  .file-uploader__loader {
    margin-bottom: $spacer;
  }

  .file-uploader__valid-file-info {
    margin: 16px 0 0 0;
    font-size: 12px;
  }
}
