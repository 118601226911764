@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$sidebar-width: 260px;

.wrapper {
  display: flex;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.sidebar {
  flex: 1 0;
  min-height: 100%;
  padding: $spacer-6 $spacer-5;

  [dir='ltr'] & {
    background: linear-gradient(90deg, $white, $gray-100);
    border-radius: $modal-content-border-radius 0 0 0;
  }

  [dir='rtl'] & {
    background: linear-gradient(90deg, $gray-100, $white);
    border-radius: 0 $modal-content-border-radius 0 0;
  }

  @include media-breakpoint-down(lg) {
    background: $white !important;
    border-radius: $modal-content-border-radius $modal-content-border-radius 0 0 !important;
    padding: $spacer-4 $spacer-4 $spacer-3;
  }
}

.content {
  width: 100%;
  max-width: $modal-xxl - $sidebar-width;
  padding: $spacer-6 $spacer-4 $spacer-5;

  :global {
    tfoot th,
    td {
      height: 50px;
    }
  }

  @include media-breakpoint-down(xxl) {
    max-width: $modal-xl - $sidebar-width;
  }

  @include media-breakpoint-down(xl) {
    max-width: $modal-lg - $sidebar-width;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer-4 $spacer-4 $spacer-3;
  }
}

.closeButton {
  position: absolute;
  top: $spacer-4;

  [dir='ltr'] & {
    right: $spacer-4;
  }

  [dir='rtl'] & {
    left: $spacer-4;
  }
}
