@import 'src/assets/styles/variables';

$min-content-height: 100px;

.step {
  margin-bottom: 0.5rem;
  padding: 0;

  &.active:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.header {
  border-radius: $input-border-radius;
  background-color: $gray-200;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
}

.content {
  padding: 1.125rem;
}
