@import '~simplebar-react/dist/simplebar.min.css';
@import 'variables';

.simplebar-track {
  $size: 8px;

  &.simplebar-vertical {
    width: $size;
  }

  &.simplebar-horizontal {
    height: $size;
  }
}

.simplebar-scrollbar {
  &::before {
    background-color: $gray-600;
  }
}
