@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.layout__content {
  padding: $spacer-4;

  @include media-breakpoint-down(xl) {
    padding: $spacer-3;
  }
}

.layout__content--withMenu {
  [dir='ltr'] & {
    margin-left: $clinicy-menu-width;
  }

  [dir='rtl'] & {
    margin-right: $clinicy-menu-width;
  }

  @include media-breakpoint-down(xl) {
    [dir='ltr'] & {
      margin-left: 0;
    }

    [dir='rtl'] & {
      margin-right: 0;
    }
  }
}
