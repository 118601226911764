@import 'src/assets/styles/variables';

.remoteTable {
  &--loading {
    overflow: hidden !important;
    pointer-events: none;

    table {
      opacity: 0.5;
    }
  }

  &--empty {
    .pagination {
      display: none;
    }
  }
}
