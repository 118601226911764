@import 'src/assets/styles/variables';

.doctor {
  color: $black;
  font-size: 12px;
}

.signature {
  margin-top: 2rem;
  width: 8rem;
}

.pageBreak {
  page-break-inside: avoid;
}
