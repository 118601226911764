@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.steps {
  position: sticky;
  top: $spacer-5;
}

.title {
  margin-bottom: $spacer-5;
  text-align: center;

  @include media-breakpoint-down(lg) {
    margin-bottom: $spacer-3;
    text-align: start;
  }
}

.step {
  $icon-color: lighten($gray-400, 10%);

  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0.75rem 0;
  transition: margin 0.2s ease-out;

  &:hover {
    .stepIcon {
      $margin: 1.063rem;

      [dir='ltr'] & {
        margin: 0 $margin 0 0;
      }

      [dir='rtl'] & {
        margin: 0 0 0 $margin;
      }
    }
  }

  .stepIcon {
    $margin: 0.75rem;

    color: $icon-color;
    transition: all 0.25s;

    [dir='ltr'] & {
      margin: 0 $margin 0 0;
    }

    [dir='rtl'] & {
      margin: 0 0 0 $margin;
    }
  }

  .stepTitle {
    color: $gray-400;
    margin: 0;
    transition: all 0.25s;
  }

  &.selected {
    .stepTitle {
      color: $gray-700;
    }
  }

  &.completed {
    .stepIcon {
      color: $success;
    }
  }

  &.invalid {
    .stepIcon {
      color: $danger;
    }
  }

  &.disabled {
    $margin: 0.75rem;

    cursor: not-allowed;

    .stepIcon {
      color: $icon-color;

      [dir='ltr'] & {
        margin: 0 $margin 0 0 !important;
      }

      [dir='rtl'] & {
        margin: 0 0 0 $margin !important;
      }
    }

    .stepTitle {
      color: $gray-400;
    }
  }
}
