@import 'src/assets/styles/variables';

$handle-size: 18px;
$handle-hover-scale: 1.5;
$handle-border-width: 2px;
$target-height: 6px;

.noUi-target {
  background: rgba($primary, 0.2);
  border: none;
  border-radius: 50px;
  box-shadow: none;
}

.noUi-horizontal {
  $position-values: calc(-#{$handle-size} / 2);
  height: $target-height;

  .noUi-handle {
    top: calc((#{$handle-size} - #{$target-height}) / -2);
    right: $position-values;
    width: $handle-size;
    height: $handle-size;
  }

  &.noUi-txt-dir-rtl {
    .noUi-handle {
      left: $position-values;
      right: auto;
    }
  }
}

.noUi-handle {
  box-shadow: none;
  border: $handle-border-width solid $primary;
  border-radius: 50%;
  outline: none;
  transition: transform 0.2s ease-out;

  &:hover,
  &:active {
    transform: scale($handle-hover-scale);
  }

  &:before,
  &:after {
    display: none;
  }
}

.noUi-connect {
  background: $primary;
}

.noUi-tooltip {
  border-radius: $border-radius;
  border: 1px solid $input-border-color;
  font-size: $font-size-sm;
  padding: 3px 4px 2px;
}

[disabled].noUi-target {
  background-color: $gray-200;

  .noUi-connect {
    background-color: $gray-300;
  }

  .noUi-handle {
    border-color: $gray-300;
  }
}
