@import 'src/assets/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.isProtected {
  cursor: pointer;
}

.copyButton {
  $margin: 2px;

  color: $pink !important;

  [dir='rtl'] & {
    margin-right: $margin;
  }

  [dir='ltr'] & {
    margin-left: $margin;
  }

  @media print {
    visibility: hidden;
  }
}
