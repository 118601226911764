@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  padding: $spacer-6 $spacer-4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // background
  background-image: url('../../../assets/images/background/background-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @include media-breakpoint-down(md) {
    padding: $spacer-3;
  }
}

.logo {
  text-align: center;
  padding: 20px 0 35px;
  user-select: none;

  img {
    cursor: pointer;
    width: 130px;
  }
}
