@import 'src/assets/styles/variables';

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  user-select: none;
  padding: $spacer-4 0;

  img {
    opacity: 0.2;
    margin-bottom: $spacer-2;
    width: 68px;
  }

  span {
    color: rgba($black, 25%);
    font-size: 19px;
    font-weight: $font-weight-medium;
    line-height: 1.4rem;
    max-width: 230px;
  }
}
