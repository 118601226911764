@import 'src/assets/styles/variables';

.editorWrapper {
  display: flex;
  flex-direction: column-reverse;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  border: $border-width solid $border-color;
}

.disabledEditorWrapper {
  display: flex;
  flex-direction: column-reverse;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  border: $border-width solid $border-color;
  background-color: $input-disabled-bg;
}

.textEditor {
  padding: 0 $spacer-3;
  max-height: 8rem;
  border: none;
}

.editorToolbar {
  border-radius: $border-radius;
  margin-bottom: 0;
  border: none;
}

.toolbarButtons {
  div {
    border-radius: $border-radius;
    width: 25px;
    height: 25px;
    box-shadow: none;
    transition: border-color 0.5s $easeClinicy;
    border-color: white;

    img {
      width: 10px;
      height: 10px;
    }

    &:hover {
      box-shadow: none;
      border-color: $clinicy-color-1;
    }
  }
}
