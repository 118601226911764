@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

$icon-size: 24px;
$link-margin-x: 15px;
$link-padding-x: 10px;
$link-padding-y: calc((#{$clinicy-menu-width} - #{$icon-size} - (#{$link-margin-x} * 2)) / 2);

.navigation {
  list-style: none;
  padding: 0 0 36px; // 40px to show the navigation above the browser elements
  margin: 0;
}

.navigation__item {
  margin: 0;
}

.navigation__link {
  border-radius: $border-radius-sm;
  color: $body-color !important;
  cursor: pointer;
  display: flex;
  margin: 0 $link-margin-x;
  padding: $link-padding-x $link-padding-y;
  text-decoration: none;
  transition: all 0.3s ease-out;

  &:hover {
    padding: $link-padding-x calc(#{$link-padding-y} + 6px);
  }
}

.navigation__link--active {
  background-color: $gray-200;
}

.navigation__linkIcon {
  [dir='ltr'] & {
    margin-right: 12px;
  }

  [dir='rtl'] & {
    margin-left: 12px;
  }
}

.navigation__linkText {
  font-size: 17px;
  line-height: 1.2rem;
  margin-top: 3px;

  .subNavigation & {
    font-size: 16px;
  }
}

.navigation__linkText--withSubMenu {
  [dir='ltr'] & {
    margin-right: 10px;
  }

  [dir='rtl'] & {
    margin-left: 10px;
  }
}

.navigation__linkChevronIcon {
  position: absolute;

  [dir='ltr'] & {
    right: 25px;
  }

  [dir='rtl'] & {
    left: 25px;
  }
}

.subNavigation {
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;

  [dir='ltr'] & {
    padding-left: 25px;
  }

  [dir='rtl'] & {
    padding-right: 25px;
  }

  .menu--expanded & {
    display: block;
  }
}
