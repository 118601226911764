@import 'src/assets/styles/variables';

.notifications {
  display: flex;
  justify-content: center;
  position: fixed;
  top: $spacer-4;
  left: 0;
  right: 0;
  z-index: $zindex-notification;
}

.notification {
  animation: slideIn 0.6s $easeOutExpo;
  background: $white;
  border-radius: $border-radius-lg;
  box-shadow: 0 5px 50px -10px rgba($gray-700, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: absolute;
  transition: opacity 0.4s, margin 0.3s, transform 0.5s $easeOutExpo;
}

.notification__icon {
  $size: 46px;

  display: flex;
  flex: 0 0 $size;
  align-items: center;
  justify-content: center;
  height: $size;
  width: $size;

  .feedbackIcon {
    transform: scale(0.5);
  }
}

.notification__message {
  font-weight: $font-weight-medium;
  line-height: 1.3;
  z-index: 2;
}

@keyframes slideIn {
  from {
    transform: scale(0.9) translateY(-40px);
  }
  to {
    transform: scale(1) translateY(0px);
  }
}
