@import 'src/assets/styles/variables';

.trainingLabel {
  position: fixed;
  bottom: 12px;
  padding: 5px 10px;
  z-index: 2000000001; // tawk.to plugin z-index

  font-size: $font-size-sm;

  border-radius: $border-radius;
  background-image: linear-gradient(40deg, $danger, lighten($danger, 5%));
  box-shadow: 0 5px 15px -2px $danger;
  color: $white;

  [dir='rtl'] & {
    right: 12px;
  }

  [dir='ltr'] & {
    left: 12px;
  }
}
