.input-with-prefix {
  &__select {
    background: transparent !important;
    border: none !important;
    flex: 20%;
    height: auto;
    margin-right: 8px;
    padding: 0 !important;

    &--rtl {
      flex: 35%;
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &__input {
    border: none !important;
    padding: 0 8px !important;

    &:hover {
      border: none !important;
    }
  }
}
