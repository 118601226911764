@import 'src/assets/styles/variables';

.passwordInput__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  direction: ltr;

  &--invalid {
    .passwordInput__showIcon {
      display: none;
    }
  }

  &--isRtl {
    direction: rtl;
  }
}

.passwordInput__showIcon {
  $padding: 11px;
  color: $gray-400;
  cursor: pointer;
  position: absolute;
  z-index: 10;

  &--isRtl {
    left: $padding;
  }

  &--isLtr {
    right: $padding;
  }
}
