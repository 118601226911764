@import 'src/assets/styles/variables';

.bookAppointment__lightBorder {
  border: 1px solid $gray-200;
}

.bookAppointment__step {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
}

.specialityList__option {
  &--clinicyId {
    align-self: center !important;
    font-size: 0.875rem;
    color: $secondary;
  }

  &.selected &--clinicyId {
    color: $white;
  }
}

.subSpecialityList__option {
  &--clinicyId {
    align-self: center !important;
    font-size: 0.875rem;
    color: $secondary;
  }

  &.selected &--clinicyId {
    color: $white;
  }
}

.selectedPatientPart {
  padding: $spacer-1;

  h5 {
    display: none;
  }
}

.patientSourceStep {
  min-width: 250px;

  div:first-of-type {
    margin-bottom: 0 !important;
  }
}
