@import 'src/assets/styles/variables';

.pagination {
  display: flex;
  justify-content: flex-end;
  user-select: none;
}

.pagination__item {
  padding: $btn-padding-y-sm $btn-padding-x-sm subtract($btn-padding-y-sm, 2px) !important;
}

.pagination__item--active {
  color: $white !important;
  background-image: $clinicy-gradient-1;
  box-shadow: $clinicy-btn-box-shadow-primary-sm;
  border-width: 0 !important;
  z-index: 2;
}
