@import 'src/assets/styles/variables';

.weekdaySelector {
  &__day {
    cursor: pointer;
    user-select: none;
    padding: 9px 12px !important;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
