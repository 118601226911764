@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

$position: $spacer-4;
$position-sm: $spacer-3;

.actions {
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 11;
  min-height: 43px; // save button height

  .iconButton {
    color: rgba($body-color, 0.7);

    &:hover,
    &:active {
      color: $body-color;
      background-color: transparent !important;
    }
  }
}

.additionalActions {
  left: $position;
  top: $position;

  @include media-breakpoint-down(md) {
    left: $position-sm;
    top: $position-sm;
  }

  [dir='rtl'] & {
    left: auto;
    right: $position;

    @include media-breakpoint-down(md) {
      left: auto;
      right: $position-sm;
    }
  }
}

.mainActions {
  right: $position;
  top: $position;

  @include media-breakpoint-down(md) {
    right: $position-sm;
    top: $position-sm;
  }

  [dir='rtl'] & {
    right: auto;
    left: $position;

    @include media-breakpoint-down(md) {
      right: auto;
      left: $position-sm;
    }
  }
}
