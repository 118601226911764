@import 'src/assets/styles/variables';

.item {
  display: inline-flex;
  align-items: center;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: $border-radius-sm;
}

.text {
  color: $gray-500;
  font-size: 0.875rem;
  font-weight: $font-weight-medium;
}

.booked {
  background-color: $status-booked;
}

.confirmed {
  background-color: $status-confirmed;
}

.checkedIn {
  background-color: $status-checked-in;
}

.waiting {
  background-color: $status-waiting;
}

.noShow {
  background-color: $status-no-show;
}

.conflicting {
  background-color: $status-conflicting;
}
