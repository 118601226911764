@import 'src/assets/styles/variables';

.doctorList {
  &__option {
    width: 100%;
    height: 95%;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  &__option:hover {
    background-color: $light-primary;
  }

  &__option.selected {
    border: 1px solid $clinicy-color-2;
  }
}
