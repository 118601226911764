@import 'src/assets/styles/variables';

.sectionTitle {
  color: $black;
  font-size: 12px;
  font-weight: 500;
}

.sectionSubtitle {
  color: $black;
  font-size: 12px;
  margin-left: $spacer-1;
}

.logo {
  max-height: 70px;
  max-width: 130px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.rowStyles {
  dl,
  dt {
    color: $body-color;
  }
}

.overflowHidden {
  overflow: hidden !important;
}

.pageBreak {
  page-break-inside: avoid;
}

.separatePage {
  @media print {
    page-break-before: always !important;
  }
}
