@import 'src/assets/styles/variables';

.sectionTitle {
  margin-top: 10px;
  margin-bottom: 6px;
  color: $black;
  font-size: 12px;
  font-weight: 500;
}

.sectionSubtitle {
  color: $black;
  font-size: 9px;
  margin-bottom: 6px;
}

.overflowHidden {
  overflow: hidden !important;
}

.icdTable {
  @media print {
    break-inside: auto;
  }
}

.pageBreak {
  @media print {
    break-inside: auto;

    table {
      display: block;
    }

    thead {
      display: block;

      tr {
        display: block;
      }

      th {
        padding-left: 0;
      }
    }

    tbody tr td {
      padding: 0.5rem 0;
    }

    thead th:nth-child(1) {
      display: inline-block;
      width: 100%;
      border: none;
    }
  }
}

.separatePage {
  @media print {
    break-before: always !important;
  }
}
