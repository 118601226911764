@import 'src/assets/styles/variables';

$table-title-fs: 1rem;
$table-fs: 8px !important;
$border-color: #d2d1d1;

.prescriptionTitle {
  font-size: 25px;
  font-weight: $font-weight-normal;
  margin-bottom: 17px;
}

.generalInformation {
  font-size: 10px;
  line-height: 1.2;
}

.boldTitle {
  font-weight: $font-weight-bold;
}

.pageBreak {
  page-break-inside: avoid;
  margin-bottom: $spacer-3;
  line-height: 1.2;
}

.logo {
  max-height: 60px;
  max-width: 75px;
  object-fit: contain;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 35px;
  margin-top: 55px;
  color: $black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.notesBorder {
  border: 1px solid $body-color;
  padding: 0.5rem;
  border-radius: 4px;
}

.overflowHidden {
  overflow: hidden !important;
}

.branchName {
  margin-bottom: 7px;
  color: $black;
  font-size: 11px;
  font-weight: $font-weight-bold;
}

.locationInfo {
  margin-right: 8px;
  color: $black;
  font-size: 10px;
}

.prescriptionInfo {
  color: $black;
  font-size: 10px;
  font-weight: 700;
}

.sectionTitle {
  margin-top: 16px;
  margin-bottom: 5px;
  color: $black;
  font-size: 11px;
  font-weight: $font-weight-bold;
}

.tableHeading {
  color: black;
  font-size: 10px;
  font-weight: $font-weight-bold;
}

.tableIndication {
  font-size: 10px;
}

.patientInfoTable {
  color: $black !important;
  border-color: $border-color !important;
  border-width: 0.5px !important;

  tbody {
    border-top-color: $border-color;
  }

  th {
    font-size: $table-fs;
    white-space: pre-wrap;
    line-height: 0.6rem;
    vertical-align: top;
    border-color: $border-color !important;
    padding: 6px !important;
    color: $black !important;
  }

  tr {
    border-top-color: $border-color;
  }

  td {
    font-size: $table-fs;
    padding: 6px !important;
    color: $black !important;
  }
}

.drugDataTable {
  font-size: 10px;
  line-height: 1.2;

  thead {
    background-color: #eeeeee;

    tr {
      th {
        border: none !important;
        color: $black !important;
        white-space: pre-wrap;
        padding: 9px 6px;
      }
    }
  }

  tbody tr td {
    background-color: #ffffff !important;
    color: black !important;
    padding: 0.6rem;
    font-size: 10px;
  }
}

.drugDetailsTable {
  border-color: $border-color;
  border-width: 0.5px !important;
  color: $black;

  th {
    font-size: $table-fs;
    padding: 6px;
    color: $black !important;
  }

  td {
    font-size: $table-fs;
    padding: 6px;
    color: $black !important;
  }

  tr {
  }

  tbody tr {
    border-top: none !important;
  }
}
