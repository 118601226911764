@import 'src/assets/styles/variables';

.doctorWorkingDayList {
  &__item {
    min-width: 415px;
  }

  .loader-wrapper {
    min-height: 580px; // approx. height of doctor info + days selector
  }
}
