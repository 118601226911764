@import './datePickerVariables';
@import '~react-datepicker/src/stylesheets/datepicker';
@import '~bootstrap/scss/mixins';

$animationOffset: 16px;
$animationScale: 90%;

// Custom Styles
@keyframes datepickerAnimation {
  from {
    transform: translateY(-$animationOffset);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes datepickerAnimationSmallBottom {
  from {
    transform: scale($animationScale) translateY(-$animationOffset);
  }
  to {
    transform: scale($animationScale) translateY(0);
  }
}

@keyframes datepickerAnimationSmallTop {
  from {
    transform: scale($animationScale) translateY($animationOffset);
  }
  to {
    transform: scale($animationScale) translateY(0px);
  }
}

.react-datepicker {
  box-shadow: $box-shadow;
  animation: datepickerAnimation 0.4s $easeOutExpo;
  user-select: none;

  @include media-breakpoint-down(sm) {
    .react-datepicker-popper[data-placement='bottom'] & {
      transform-origin: top center;
      animation: datepickerAnimationSmallBottom 0.4s $easeOutExpo both;
    }

    .react-datepicker-popper[data-placement='top'] & {
      transform-origin: bottom center;
      animation: datepickerAnimationSmallTop 0.4s $easeOutExpo both;
    }
  }
}

.react-datepicker-popper {
  z-index: $zindex-date-picker;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  .form-control {
    &[readonly]:not([disabled]) {
      background-color: $input-bg;
    }
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: $font-weight-medium;
}

.react-datepicker__navigation {
  z-index: $zindex-date-picker;
}

.react-datepicker__monthPicker {
  margin: 0.375rem;
}

.react-datepicker__month {
  .react-datepicker__month-text {
    align-items: center;
    border-radius: $datepicker__day-border-radius;
    display: inline-flex;
    height: 2.25rem;
    justify-content: center;
    margin: 0.188rem;
    width: 5rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: $gray-200;
    }

    &--today {
      font-weight: $font-weight-normal;
    }

    &--keyboard-selected {
      color: $datepicker__text-color;
      background-color: transparent;

      &:hover {
        background-color: $gray-200;
      }
    }
  }

  &--selected {
    color: $white !important;
    background-color: $datepicker__selected-color !important;
    font-weight: $font-weight-medium;

    &:hover {
      background-color: darken($datepicker__selected-color, 4%) !important;
    }
  }

  &--disabled {
    color: $datepicker__muted-color !important;
  }
}

.react-datepicker__day-name {
  font-weight: $font-weight-medium;
}

.react-datepicker__day {
  border-radius: $datepicker__day-border-radius;
  transition: background-color 0.2s;

  &:hover {
    border-radius: $datepicker__day-border-radius !important;
    background-color: $gray-200;
  }

  &--today {
    font-weight: $font-weight-medium;
  }

  &--highlighted {
    border-radius: $datepicker__day-border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;
  }

  &--outside-month:not(.react-datepicker__day--disabled) {
    color: rgba($datepicker__text-color, 0.5);
    transition: color 0.2s;

    &:hover {
      color: $datepicker__text-color;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__day-border-radius;
    background-color: $datepicker__selected-color;
    color: #fff !important;
    font-weight: $font-weight-medium;

    &:hover {
      background-color: darken($datepicker__selected-color, 4%);
      font-weight: $font-weight-medium;
    }

    &:focus {
      outline: none;
    }
  }
}

.react-datepicker__day--disabled {
  cursor: not-allowed;

  &:hover {
    background-color: transparent;
    font-weight: $font-weight-normal;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  padding: 0 8px;
  right: 0;

  [dir='rtl'] & {
    left: 0;
    right: auto;
  }

  &::after {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: hsl(0, 0%, 80%);
    font-size: 16px;
    content: '\00d7';
    transition: color 0.2s;
    margin-top: -2px;
  }

  &:hover {
    &::after {
      color: hsl(0, 0%, 60%);
    }
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  border-radius: 0 0 $datepicker__border-radius $datepicker__border-radius;
  cursor: pointer;
  text-align: center;
  font-weight: $font-weight-medium;
  padding: 7px 0 8px;
  clear: left;
  transition: background-color 0.2s;

  &:hover {
    background: $gray-200;
  }
}

.react-datepicker.datePicker__withTime {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  .react-datepicker__time-container {
    width: $datepicker__time-container-size;

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
      }
    }
  }

  //Time picker is placed below on smaller devices
  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .react-datepicker__time-container {
      border-right: 1px solid $datepicker__border-color;
      border-left: unset;
      border-bottom-left-radius: $datepicker__border-radius;
      min-width: 100%;
      min-height: 100%;

      .react-datepicker__header--time {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px solid $datepicker__border-color;
        min-width: 100%;

        .react-datepicker-time__header {
          font-size: 1rem;
        }
      }

      .react-datepicker__time {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $datepicker__border-radius;
        min-height: 100%;

        .react-datepicker__time-box {
          border-bottom-left-radius: $datepicker__border-radius;
          min-width: 100%;
          margin-top: 6px;

          .react-datepicker__time-list {
            display: flex;
            flex-direction: row;
            max-height: 40px;

            .react-datepicker__time-list-item {
              padding: 6px !important;
            }
          }
        }
      }
    }
  }

  [dir='rtl'] & {
    .react-datepicker__time-container {
      border-right: 1px solid $datepicker__border-color;
      border-left: unset;
      border-bottom-left-radius: $datepicker__border-radius;

      .react-datepicker__header--time {
        border-top-left-radius: $datepicker__border-radius;
        border-top-right-radius: 0;
      }

      .react-datepicker__time {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $datepicker__border-radius;

        .react-datepicker__time-box {
          border-bottom-left-radius: $datepicker__border-radius;
        }
      }
    }

    .react-datepicker__header--custom {
      border-top-left-radius: 0;
    }
  }

  .react-datepicker__header--custom {
    border-top-right-radius: 0;
  }
}

.react-datepicker--time-only {
  .react-datepicker__time-container {
    width: $datepicker__time-only-time-container-size !important;

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
      }
    }
  }
}

.react-datepicker__time-list-item {
  padding: 5px 4px 4px !important;

  &--selected {
    font-weight: $font-weight-medium !important;
  }
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

// Custom Arrows
.datePicker__arrowButton {
  [dir='rtl'] & {
    transform: rotate(180deg);

    &:hover {
      transform: rotate(180deg) !important;
    }
  }
}

.datePicker--inactive {
  &:before {
    cursor: not-allowed;
    background-color: rgba($white, 0.5);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-date-picker + 1;
  }
}
