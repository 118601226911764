@import 'src/assets/styles/variables';

$color: lighten($gray-400, 10%);

.noDataMessage {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 180px;
  padding: $spacer;
  user-select: none;

  &__icon {
    color: $color;
    margin-bottom: $spacer-2;
  }

  &__text {
    color: $color;
    font-weight: $font-weight-medium;
  }
}
