@import 'src/assets/styles/variables';

$slot-button-width: 106px;
$slot-button-height: 30px;
$slot-button-margin: 4px;

.timeFormatter {
  text-align: center;

  &__header {
    margin-bottom: $spacer;
  }

  &__workingTime {
    margin-bottom: $spacer;
  }

  &__slots {
    min-width: ($slot-button-width * 3) + ($slot-button-margin * 6);
  }

  &__slot {
    font-size: 14px;
    height: $slot-button-height;
    margin: $slot-button-margin !important;
    overflow: hidden;
    width: $slot-button-width;
    padding: 0 !important;
  }
}
