@import 'src/assets/styles/variables';

.text {
  min-width: 130px;

  [dir='ltr'] & {
    margin-right: $spacer-1;
    margin-left: $spacer-2;
  }

  [dir='rtl'] & {
    margin-right: $spacer-2;
    margin-left: $spacer-1;
  }
}
