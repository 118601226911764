@import 'src/assets/styles/variables';

.page-header {
  margin-bottom: calc(#{$spacer} / 2);

  .page-header__title {
    margin-bottom: $spacer;
  }

  .page-header__actions {
    display: block;
    text-align: right;
    margin-bottom: $spacer;

    html[dir='rtl'] & {
      text-align: left;
    }
  }
}
