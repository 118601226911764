@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/variables';

.wrapper {
  $margin: 350px;

  bottom: $spacer;
  display: flex;
  justify-content: center;
  margin: 0 0 0 $margin;
  pointer-events: none;
  position: sticky;
  z-index: $zindex-sticky;

  [dir='rtl'] & {
    margin: 0 $margin 0 0;
  }

  @include media-breakpoint-down(xl) {
    margin: 0 !important;
  }
}

.pagination {
  align-items: center;
  background-color: $white;
  border-radius: $border-radius-pill;
  box-shadow: $clinicy-toolbar-box-shadow;
  display: inline-flex;
  padding: 6px;
  pointer-events: auto;
  user-select: none;
}

.page {
  padding: 0 6px;
  margin: 0 0 -1px;
}

.button {
  border-radius: $border-radius-pill !important;
  min-width: 36px;

  :global {
    .icon {
      [dir='rtl'] & {
        transform: rotate(180deg);
      }
    }
  }
}
