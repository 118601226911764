@import '~bootstrap/scss/mixins';
@import 'src/assets/styles/variables';

.refreshingBadgeWrapper {
  position: absolute;
  bottom: $spacer-2;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: $zindex-sticky;
}

.refreshingBadge {
  align-items: center;
  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $clinicy-toolbar-box-shadow;
  display: flex;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  height: 48px;
  justify-content: center;
  padding: 0 $spacer-4;
}
