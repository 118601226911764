@import '~bootstrap/scss/mixins';
@import 'variables';

body {
  //Disables trackpad two finger swipe forward/backward on mac
  overscroll-behavior-x: contain;

  &.withExpandedMenu {
    @include media-breakpoint-down(xl) {
      overflow: hidden;
    }
  }
}

// Global RTL
[dir='rtl'] {
  // margin start
  .ms-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ms-1 {
    margin-right: $spacer-1 !important;
    margin-left: 0 !important;
  }

  .ms-2 {
    margin-right: $spacer-2 !important;
    margin-left: 0 !important;
  }

  .ms-3 {
    margin-right: $spacer-3 !important;
    margin-left: 0 !important;
  }

  .ms-4 {
    margin-right: $spacer-4 !important;
    margin-left: 0 !important;
  }

  .ms-5 {
    margin-right: $spacer-5 !important;
    margin-left: 0 !important;
  }

  @include media-breakpoint-up(md) {
    .ms-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .ms-md-1 {
      margin-right: $spacer-1 !important;
      margin-left: 0 !important;
    }

    .ms-md-2 {
      margin-right: $spacer-2 !important;
      margin-left: 0 !important;
    }

    .ms-md-3 {
      margin-right: $spacer-3 !important;
      margin-left: 0 !important;
    }

    .ms-md-4 {
      margin-right: $spacer-4 !important;
      margin-left: 0 !important;
    }

    .ms-md-5 {
      margin-right: $spacer-5 !important;
      margin-left: 0 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .ms-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .ms-lg-1 {
      margin-right: $spacer-1 !important;
      margin-left: 0 !important;
    }

    .ms-lg-2 {
      margin-right: $spacer-2 !important;
      margin-left: 0 !important;
    }

    .ms-lg-3 {
      margin-right: $spacer-3 !important;
      margin-left: 0 !important;
    }

    .ms-lg-4 {
      margin-right: $spacer-4 !important;
      margin-left: 0 !important;
    }

    .ms-lg-5 {
      margin-right: $spacer-5 !important;
      margin-left: 0 !important;
    }
  }

  // margin end
  .me-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .me-1 {
    margin-right: 0 !important;
    margin-left: $spacer-1 !important;
  }

  .me-2 {
    margin-right: 0 !important;
    margin-left: $spacer-2 !important;
  }

  .me-3 {
    margin-right: 0 !important;
    margin-left: $spacer-3 !important;
  }

  .me-4 {
    margin-right: 0 !important;
    margin-left: $spacer-4 !important;
  }

  .me-5 {
    margin-right: 0 !important;
    margin-left: $spacer-5 !important;
  }

  @include media-breakpoint-up(md) {
    .me-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .me-md-1 {
      margin-right: 0 !important;
      margin-left: $spacer-1 !important;
    }

    .me-md-2 {
      margin-right: 0 !important;
      margin-left: $spacer-2 !important;
    }

    .me-md-3 {
      margin-right: 0 !important;
      margin-left: $spacer-3 !important;
    }

    .me-md-4 {
      margin-right: 0 !important;
      margin-left: $spacer-4 !important;
    }

    .me-md-5 {
      margin-right: 0 !important;
      margin-left: $spacer-5 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .me-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .me-lg-1 {
      margin-right: 0 !important;
      margin-left: $spacer-1 !important;
    }

    .me-lg-2 {
      margin-right: 0 !important;
      margin-left: $spacer-2 !important;
    }

    .me-lg-3 {
      margin-right: 0 !important;
      margin-left: $spacer-3 !important;
    }

    .me-lg-4 {
      margin-right: 0 !important;
      margin-left: $spacer-4 !important;
    }

    .me-lg-5 {
      margin-right: 0 !important;
      margin-left: $spacer-5 !important;
    }
  }
}

// Text Align
.text-left {
  text-align: left !important;

  [dir='rtl'] & {
    text-align: right !important;
  }
}

.text-right {
  text-align: right !important;

  [dir='rtl'] & {
    text-align: left !important;
  }
}

.text-start {
  text-align: left !important;

  [dir='rtl'] & {
    text-align: right !important;
  }
}

.text-end {
  text-align: right !important;

  [dir='rtl'] & {
    text-align: left !important;
  }
}

// Text Wrap
.text-pre-line {
  white-space: pre-line;
}

// Description Lists
dl {
  margin: 0;
  padding: 0;

  dt {
    color: $secondary;
    font-size: $font-size-sm;
    margin: 0 0 $spacer-1;
    padding: 0;
  }

  dd {
    margin: 0 0 $spacer-2;
    padding: 0;
    word-wrap: break-word;
    word-break: break-word;
  }

  &.horizontal {
    dt {
      margin-bottom: $spacer-1;
    }

    dd {
      margin-bottom: $spacer-3;
    }
  }
}

// Button
.btn {
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  will-change: transform;
}

// button-size
.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
  line-height: $line-height-sm;
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

// button-variant
.btn-primary {
  $background: $primary;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white,
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white
  );
}

.btn-primary-gradient {
  @extend .btn-primary;
  background-clip: content-box, border-box;
  background-image: $clinicy-gradient-1, $clinicy-gradient-1;
  background-origin: border-box;
  border: $btn-border-width solid transparent;

  &:active,
  &:hover,
  &:focus {
    border: $btn-border-width solid transparent;
  }
}

.btn-secondary {
  $background: $secondary;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white,
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white
  );
}

.btn-info {
  $background: $info;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white,
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white
  );
}

.btn-success {
  $background: $success;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white,
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white
  );
}

.btn-warning {
  $color: $gray-800;
  $background: $warning;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $color,
    $hover-background,
    $hover-background,
    $color,
    $background,
    $background,
    $color,
    $hover-background,
    $hover-background,
    $color
  );
}

.btn-danger {
  $background: $danger;
  $hover-background: lighten($background, 6%);
  @include button-variant(
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white,
    $background,
    $background,
    $white,
    $hover-background,
    $hover-background,
    $white
  );
}

.btn-light {
  $color: $body-color;
  $background: $gray-200;
  $active-background: $gray-100;
  @include button-variant(
    $background,
    $background,
    $color,
    $background,
    $background,
    $color,
    $active-background,
    $active-background,
    $color,
    $background,
    $background,
    $color
  );
}

.btn-flat {
  $color: $gray-600;
  $background: rgba($white, 0);
  $hover-background: $gray-200;
  $hover-color: $body-color;
  @include button-variant(
    $background,
    $background,
    $color,
    $hover-background,
    $hover-background,
    $hover-color,
    $hover-background,
    $hover-background,
    $hover-color,
    $background,
    $background,
    $color
  );
}

.btn-link {
  $color: $body-color;

  color: $color;
  user-select: auto;
  text-decoration: underline;

  &:active,
  &:hover {
    color: $primary;
  }
}

.btn-icon {
  $body-color: $gray-200;

  border: none;
  padding: 6px !important;
  line-height: 1;

  &:hover,
  &:active {
    background-color: $body-color !important;
    transform: none !important;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.4;

    &:hover {
      background: transparent;
    }
  }
}

// button-outline-variant
.btn-outline-primary {
  $color: $primary;
  @include button-outline-variant($color, $color, $light-primary, $color, $color);
}

.btn-outline-secondary {
  $color: $secondary;
  @include button-outline-variant($color, $color, $light-secondary, $color, $color);
}

.btn-outline-info {
  $color: $info;
  @include button-outline-variant($color, $color, $light-info, $color, $color);
}

.btn-outline-success {
  $color: $success;
  @include button-outline-variant($color, $color, $light-success, $color, $color);
}

.btn-outline-warning {
  $color: $warning;
  @include button-outline-variant($color, $color, $light-warning, $color, $color);
}

.btn-outline-danger {
  $color: $danger;
  @include button-outline-variant($color, $color, $light-danger, $color, $color);
}

// Button Responsive
.btn-responsive {
  @include media-breakpoint-down(md) {
    display: block;
    margin: 0 0 $spacer-2 !important;
    width: 100%;

    [dir='rtl'] & {
      margin: 0 0 $spacer-2 !important;
    }

    &:hover {
      transform: none;
    }
  }

  &--lg {
    @include media-breakpoint-down(lg) {
      display: block;
      margin: 0 0 $spacer-2 !important;
      width: 100%;

      [dir='rtl'] & {
        margin: 0 0 $spacer-2 !important;
      }

      &:hover {
        transform: none;
      }
    }
  }
}

// Button Icon
.buttonIcon {
  $space: $spacer-2;
  margin-top: -0.2rem;

  &--bg {
    background-color: $white;
    border-radius: 50%;
    color: $body-color;
    padding: 3px;
  }

  &--left {
    [dir='ltr'] & {
      margin-left: -$space;
      margin-right: $space;
    }

    [dir='rtl'] & {
      margin-left: $space;
      margin-right: -$space;
    }
  }

  &--right {
    [dir='ltr'] & {
      margin-left: $space;
      margin-right: -$space;
    }

    [dir='rtl'] & {
      margin-left: -$space;
      margin-right: $space;
    }
  }
}

// Accordion
.accordion-button {
  &::after {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

// Badge
.badge {
  font-weight: $font-weight-medium;

  &.bg-light {
    color: $body-color;
  }

  &.bg-light-primary {
    color: darken($primary, 8%);
  }

  &.bg-light-secondary {
    color: darken($secondary, 14%);
  }

  &.bg-light-success {
    color: darken($success, 6%);
  }

  &.bg-light-info {
    color: darken($info, 8%);
  }

  &.bg-light-warning {
    color: darken($warning, 14%);
  }

  &.bg-light-danger {
    color: $danger;
  }
}

// Dropdown
@keyframes dropdownMenuAnimation {
  0% {
    margin-top: $spacer-3;
    opacity: 0;
  }

  100% {
    margin-top: $spacer-1;
    opacity: 1;
  }
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
  animation: dropdownMenuAnimation 0.25s both;
}

.dropdown-item {
  [dir='rtl'] & {
    text-align: right;
  }
}

// Navigation tabs and pills
.nav-pills,
.nav-tabs {
  padding: 0;
  margin-bottom: $spacer-4;
  user-select: none;

  .nav-link {
    cursor: pointer;
  }
}

.nav-pills {
  .nav-link {
    transition: all 0s, transform 0.2s ease-out;

    &:hover {
      transform: translateY(-3px);
      transition: all 0s, transform 0.3s ease-out;
    }

    &.active {
      background-image: $clinicy-gradient-1;
      box-shadow: $clinicy-btn-box-shadow-primary;
      font-weight: $font-weight-medium;
    }
  }
}

// Tabs
.tab-pane {
  animation: fadeIn 0.5s, slideInUp 0.8s $easeOutExpo;
}

// Table
.table {
  margin-bottom: 0;

  thead {
    border-top: none !important;
  }

  tbody {
    border-top: none !important;

    tr:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }

  tfoot {
    border-top: 1px solid $table-border-color !important;

    tr:last-child {
      th {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    border-bottom: 1px solid $table-border-color;
  }

  th {
    white-space: nowrap;
  }

  td {
    font-size: $font-size-sm;
    line-height: 1.2;
    transition: all 0.3s;
  }

  // Table Link
  a:not(.btn) {
    color: $table-color;
    cursor: pointer;
    font-size: $font-size-sm;
    text-decoration: underline;
    transition: all 0.25s;

    &:active,
    &:hover {
      color: $primary;
    }
  }

  // Table Sticky Column
  .sticky {
    background: $white;
    position: sticky;

    &--start {
      [dir='ltr'] & {
        border-top-left-radius: $border-radius;
        left: 0;
      }

      [dir='rtl'] & {
        border-top-right-radius: $border-radius;
        right: 0;
      }
    }

    &--end {
      [dir='ltr'] & {
        border-top-right-radius: $border-radius;
        right: 0;
      }

      [dir='rtl'] & {
        border-top-left-radius: $border-radius;
        left: 0;
      }
    }
  }
}

.table-sm {
  font-size: $font-size-sm;
}

.tableList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: $spacer-1 0;
  }
}

.table-row-cursor-pointer {
  tbody tr {
    cursor: pointer;
  }
}

// Modal
.modal-xxl {
  @include media-breakpoint-up(sm) {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  @include media-breakpoint-up(lg) {
    --bs-modal-width: #{$modal-lg};
  }

  @include media-breakpoint-up(xl) {
    --bs-modal-width: #{$modal-xl};
  }

  @include media-breakpoint-up(xxl) {
    --bs-modal-width: #{$modal-xxl};
  }
}

body.modal-open {
  overflow: hidden !important;
}

.modal-backdrop {
  backdrop-filter: blur(0.2rem);
  background-color: rgba($clinicy-color-1, 0.27);
}

.modal-header {
  [dir='ltr'] & {
    padding: $spacer-3 $spacer-3 $spacer-3 $modal-inner-padding;
  }

  [dir='rtl'] & {
    padding: $spacer-3 $modal-inner-padding $spacer-3 $spacer-3;
  }

  .btn-close {
    margin: 0 0 0 auto;

    [dir='rtl'] & {
      margin: 0 auto 0 0;
    }
  }
}

.modal-body {
  padding: $modal-inner-padding;
}

.modal-footer {
  padding: 0 $modal-inner-padding $modal-inner-padding;
}

.modal-content--fullWidth {
  margin-right: -$modal-inner-padding;
  margin-left: -$modal-inner-padding;
}

// Sticky
.sticky-top {
  &--withGutter {
    top: $grid-gutter-width;
  }

  &--withHeader {
    top: add($clinicy-header-height, $grid-gutter-width);
  }

  &--withGutter,
  &--withHeader {
    @include media-breakpoint-down(lg) {
      position: relative;
      top: auto;
    }
  }
}

// Form

.form-control {
  &[readonly] {
    background-color: $input-disabled-bg;
  }
}

.input-group {
  [dir='rtl'] & {
    flex-direction: row-reverse;
  }
}

.label__link {
  position: absolute;
  top: 0;
  font-size: $form-label-font-size;

  [dir='ltr'] & {
    right: 0;
  }

  [dir='rtl'] & {
    left: 0;
  }
}

.form-check {
  [dir='rtl'] & {
    padding-left: 1em;
  }

  &.form-check-inline {
    [dir='rtl'] & {
      margin-right: 0;
    }
  }

  .form-check-input {
    [dir='rtl'] & {
      float: right;
      margin-left: 0.5em;
    }
  }
}

// hide number input arrows
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// autofill background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
  transition: none !important;
}

input:-webkit-autofill::first-line {
  font-family: $input-font-family;
}

// React Bootstrap Table
.react-bootstrap-table-pagination-list {
  margin-top: $spacer-2;
}

.react-bootstrap-table-sort-order {
  .caret {
    content: '';
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg transform='rotate(-180 12 12)'%3E%3Cpath fill='%23B6C2C4' d='m7,15l5,-6l5,6l-10,0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center 3px;
    background-size: contain;
    display: inline-block;
    margin: 0 1px;
    width: 16px;
    height: 16px;
  }

  &.dropup {
    .caret {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg%3E%3Cpath fill='%23B6C2C4' d='m7,15l5,-6l5,6l-10,0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.sortable {
  cursor: pointer;

  .order {
    content: '';
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_1'%3E%3Cpath id='svg_2' d='m7,10l5,-6l5,6l-10,0z' fill='%23B6C2C4'/%3E%3C/g%3E%3Cg transform='rotate(-180 12 17)' id='svg_4'%3E%3Cpath id='svg_3' d='m7,20l5,-6l5,6l-10,0z' fill='%23B6C2C4'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center 3px;
    background-size: contain;
    display: inline-block;
    margin: 0 1px;
    width: 16px;
    height: 16px;
  }
}

// Min width
.min-w-180 {
  min-width: 180px;
}

.code {
  &--key {
    color: $gray-700;
    background-color: $gray-200;
    border: 1px solid $gray-300;
    border-radius: $border-radius-sm;
    padding: 1px 4px;
  }
}

// Google Map
.pac-container {
  position: absolute;
  z-index: $zindex-popover;
}
