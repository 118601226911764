@import 'src/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.wrapper {
  $shadow-size: 1.5rem;

  display: flex;
  justify-content: flex-end;
  background-color: $white;
  border-radius: 0 0 $modal-content-border-radius $modal-content-border-radius;
  border-top: 1px solid $gray-100;
  bottom: 0;
  box-shadow: 0 (-$shadow-size) $shadow-size -0.5rem rgba($box-shadow-color, 0.05);
  padding: $spacer-2 $spacer-4;
  position: sticky;
  z-index: $zindex-date-picker - 1;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.wrapper > * {
  [dir='ltr'] & {
    margin-right: $spacer-2;
  }

  [dir='rtl'] & {
    margin-left: $spacer-2;
  }

  &:last-child {
    margin: 0;
  }
}

.navButton {
  flex: 0 1 80px;

  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.button {
  flex: 0 1 160px;

  @include media-breakpoint-down(lg) {
    flex: 1 1 50%;
  }
}
