@import 'src/assets/styles/variables';

$transition-time: 0.3s;
$collapse-border-radius: $border-radius;

.wrapper {
  border: 1px solid $border-color;
  border-radius: $collapse-border-radius;
}

.header {
  align-items: center;
  background: $gray-100;
  border-bottom: 1px solid $border-color;
  border-top-left-radius: $collapse-border-radius;
  border-top-right-radius: $collapse-border-radius;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: $spacer-3;
  user-select: none;
}

.headerTitle {
  margin-bottom: 0;
}

.content {
  padding: $spacer-3 $spacer-3 $spacer-1;
}

.icon {
  transition: transform $transition-time;
}

.iconActive {
  transform: rotate(180deg);
  transform-origin: center;
}
