@import 'src/assets/styles/variables';

$avatar-size-xs: 22px;
$avatar-size-sm: 40px;
$avatar-size: 60px;
$avatar-size-lg: 80px;

.avatar {
  background-color: $light-primary;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  color: $primary;
  font-weight: $font-weight-medium;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: $avatar-size;
  width: $avatar-size;

  .avatar__status {
    width: 16px;
    height: 16px;
  }

  &--xs {
    font-size: $font-size-sm;
    height: $avatar-size-xs;
    width: $avatar-size-xs;
  }

  &--sm {
    font-size: $font-size-sm;
    height: $avatar-size-sm;
    width: $avatar-size-sm;

    .avatar__status {
      height: 13px;
      width: 13px;
    }
  }

  &--lg {
    font-size: $font-size-lg;
    height: $avatar-size-lg;
    width: $avatar-size-lg;

    .avatar__status {
      height: 20px;
      width: 20px;
    }
  }
}

.avatar__status {
  background-color: $success;
  border: 2px solid $white;
  border-radius: 50%;
  bottom: 0;
  position: absolute;

  [dir='ltr'] & {
    right: 0;
  }

  [dir='rtl'] & {
    left: 0;
  }
}

.avatar__status--offline {
  background-color: $gray-400;
}
