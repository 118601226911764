@import 'src/assets/styles/variables';

.pageBreak {
  @media print {
    break-inside: auto;
  }
}

.wrapper {
  color: black;
  margin-bottom: $spacer-3;
}

.itemWrapper {
  margin-top: $spacer-2;
  @media print {
    break-inside: auto;
  }
}

.title {
  font-size: 12px;
  margin-bottom: $spacer-1;
}

.content {
  font-size: 9px;
  line-height: 1.2;
  letter-spacing: 0;
  display: block;
}

.separator {
  height: 1px;
  color: black;
}
