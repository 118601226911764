@import 'src/assets/styles/variables';

.mapWrapper {
  background-color: $gray-100;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  padding: 14px;
}

.mapWrapperInvalid {
  border: 1px solid $danger;
}

.map {
  width: 100%;
  height: 380px;
  max-height: 80vh;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  overflow: hidden;
}

.mapInput {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: $spacer-2;
}
